.containerWithButton {
	padding-bottom: 80px;
}
.buttonPanic {
	text-align: center;
	width: 100vw;
	display: block;
	text-decoration: underline;
	padding-top: 16px;
	font-weight: bold;
}
.buttonPanic button {
	border: none;
	background-color: transparent;
	color: var(--color-indigo);
	font-weight: bold;
	text-decoration: underline;
}
